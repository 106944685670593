import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import UnderlinedButton from '../components/Button/UnderlinedButton';
import Gallery from '../components/Gallery/Gallery';
import Heading from '../components/Heading';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import TestimonyCarouselGrid from '../components/TestimonyCarouselGrid';

const translations = [
  {
    locale: 'en',
    url: '/en/creations/',
  },
];

export default function RealisationsPage({ data }) {
  const images = data.allImage.nodes.map(({ childImageSharp }) => {
    const gatsbyImageData = getImage(childImageSharp);

    return {
      gatsbyImageData,
    };
  });
  return (
    <Layout translations={translations}>
      <article className="space-y-48">
        <PageHeader>Réalisations</PageHeader>

        <div className="container text-xl text-center">
          <Heading level={2} className="text-5xl">
            C’est plus qu’une guitare. <br />
            C’est une œuvre de Michel Pellerin.
          </Heading>

          <p className="max-w-prose mx-auto">
            La créativité et le talent de luthier de Michel Pellerin n’ont pas
            de limites. Peu importe le défi et la complexité, Michel réussi
            toujours a impressionné avec un instrument fabriqué avec minutie,
            doigté et précision. De plus, l’harmonie des matériaux crée un son
            riche et inégalé.
          </p>
        </div>

        <section className="container">
          <div className=" wide-content-contained">
            <Gallery pictures={images} />
          </div>
        </section>

        {/* TODO: add video */}
        {/* <section className="container">
          <div className="container text-xl text-center">
            <div>
              <Heading level={2} className="text-5xl">
                Des guitares qui sonnent aussi en vidéo
              </Heading>
            </div>
          </div>
        </section> */}

        <section>
          <div className="container text-xl text-center">
            <div>
              <Heading level={2} className="text-5xl">
                Des clients chantent les louanges de leur&nbsp;Pellerin
              </Heading>
            </div>
          </div>

          <TestimonyCarouselGrid className="my-12" />

          <div className="container text-center">
            <UnderlinedButton to="/temoignages" className="text-xl my-5">
              Lire les témoignages
            </UnderlinedButton>
          </div>
        </section>
      </article>
    </Layout>
  );
}

export const query = graphql`
  query {
    allImage: allFile(
      filter: { relativeDirectory: { eq: "realisationsPictures" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(width: 760)
        }
      }
    }
  }
`;
